import Swiper, {Navigation} from "swiper";

const TestimonialSwiper = (element) => {
  if (!element) return;

  init(element);
  
  function init(container) {
    const swiperElement = container;
    
    enableSwiper();

    function enableSwiper() {
      const swiper = new Swiper(swiperElement, {
        modules: [Navigation],
        loop: true,
        navigation: {
          prevEl: '.nav-prev-next__button--prev',
          nextEl: '.nav-prev-next__button--next',
        },
      });
    }
  }
}

export default TestimonialSwiper;
