// Search

const Search = () => {
  const modal = document.querySelector('[data-search="modal"]');
  const toggles = document.querySelectorAll('[data-search="toggle"]');
  const searchOpenClass = 'search-open';
  
  if (!modal) return;

  function handleClick(e) {
    document.body.classList.toggle(searchOpenClass);
  }

  toggles.forEach(toggle => toggle.addEventListener('click', handleClick));
  document.addEventListener('keyup', e => {
    if (e.key === 'Escape' && document.body.classList.contains(searchOpenClass)) {
      document.body.classList.remove(searchOpenClass);
    }
  });

  // Focus
  modal.addEventListener('transitionend', e => {
    if (e.propertyName !== 'transform') return;
    
    if (document.body.classList.contains(searchOpenClass)) {
      modal.querySelector('input').focus();
    } else {
      toggles[0].focus();
    }
  })

  // Price slider
  const searchPriceSlider = document.getElementById('search-price-slider');
  if (!searchPriceSlider) return;

  const priceSlider = document.getElementById('search-price-slider');
  const inputFrom = document.getElementById('search-price-from');
  const inputTo = document.getElementById('search-price-to');

  noUiSlider.create(priceSlider, {
    start: [Number(searchPriceSlider.dataset.initFrom), Number(searchPriceSlider.dataset.initTo)],
    connect: true,
    step: 1000,
    range: {
      'min': Number(searchPriceSlider.dataset.min),
      'max': Number(searchPriceSlider.dataset.max)
    },
    tooltips: {
      to: function(value) {
        return formatCzk(value);
      },
      from: function(value) {
        return formatCzk(value);
      }
    }
  });

  priceSlider.noUiSlider.on('update', function (values, handle, unencoded) {
    inputFrom.value = Math.round(unencoded[0]);
    inputTo.value = Math.round(unencoded[1]);
  });

  function formatCzk(value) {
    return new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(value);
  }
}

export default Search;
