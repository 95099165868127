import Nav from "./modules/nav.js";
import Collapse from "./modules/collapse";
import TestimonialSwiper from "./modules/testimonial-swiper";
import Search from "./modules/search";
import Swiper, {Navigation, Pagination, Autoplay, Grid} from "swiper";

// Main JS file

function init() {
  // Nav
  Nav();
  
  // Collapse
  const collapseElements = document.querySelectorAll('.collapse');
  Collapse(collapseElements);

  // Homepage cover swiper
  const hpCoverSwiper = document.querySelector('.cover--hp .cover__slider');
  if (hpCoverSwiper) {
    const hpSwiper = new Swiper(hpCoverSwiper, {
      modules: [Navigation, Pagination, Autoplay],
      speed: 700,
      autoplay: {
        delay: 3000,
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        prevEl: '.nav-prev-next__button--prev',
        nextEl: '.nav-prev-next__button--next',
      },
    });
  }

  // Detail cover swiper
  const detailCoverSwiper = document.querySelector('.cover--detail .cover__slider');
  if (detailCoverSwiper) {
    const detailSwiper = new Swiper(detailCoverSwiper, {
      modules: [Navigation, Pagination, Autoplay],
      speed: 700,
      autoplay: {
        delay: 3000,
      },
      loop: true,
      slidesPerView: 2,
      spaceBetween: 2,
      // centeredSlides: true, // TODO? center 2 slides
      freeMode: {
        sticky: true,
      },
      breakpoints: {
        600: {
          // spaceBetween: 4,
        },
      },
      navigation: {
        prevEl: '.nav-prev-next__button--prev',
        nextEl: '.nav-prev-next__button--next',
      },
    });
  }

  // Gallery swiper
  const gallerySwiper = document.querySelector('.gallery');
  if (gallerySwiper) {
    const galSwiper = new Swiper(gallerySwiper, {
      modules: [Grid],
      slidesPerView: 2,
      spaceBetween: 2,
      grid: {
        rows: 2,
        fill: 'row',
      },
      breakpoints: {
        600: {
          slidesPerView: 3,
          spaceBetween: 8,
        },
        900: {
          slidesPerView: 4,
          spaceBetween: 16,
        },
        1600: {
          slidesPerView: 5,
          spaceBetween: 16,
        },
      },
    });
  }

  // Common swiper
  const swipers = document.querySelectorAll('.common-swiper');
  if (swipers.length > 0) {
    swipers.forEach(swiper => {
      const s = new Swiper(swiper, {
        modules: [Navigation],
        slidesPerView: 1,
        autoHeight: true,
        navigation: {
          prevEl: '.nav-prev-next__button--prev',
          nextEl: '.nav-prev-next__button--next',
        },
      });
    })
  }

  // Testimonial swiper
  const testimonialSwiper = document.querySelector('.testimonials');
  if (testimonialSwiper) {
    TestimonialSwiper(testimonialSwiper);
  }

  // Search price slider
  const searchModal = document.querySelector('[data-search="modal"]');
  if (searchModal) {
    Search();
  }

}

window.addEventListener('DOMContentLoaded', init);
