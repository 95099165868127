// Collapse

const Collapse = (elements) => {
  if (elements.length < 1) return;

  const hash = location.hash.substring(1);
  const hashCollapse = document.getElementById(hash);

  elements.forEach(collapse => init(collapse));

  function init(collapse) {
    const toggle = collapse.querySelector('.collapse__toggle');
    const accordion = collapse.closest('.accordion');
    
    toggle.addEventListener('click', (e) => {
      e.preventDefault();

      if (accordion) {
        const autoClose = accordion.hasAttribute('data-auto-close');

        if (autoClose) {
          const openItems = accordion.querySelectorAll('details[open]');
          openItems.forEach(collapse => toggleCollapse(collapse, false));
        }
      }
      toggleCollapse(collapse, !collapse.open, true);
    });
    
    if (accordion && accordion.contains(hashCollapse)) {
      const openItems = accordion.querySelectorAll('details[open]');
      openItems.forEach(collapse => collapse.open = false);
      toggleCollapse(hashCollapse, true);
      return;
    }

    if (collapse.open) {
      toggleCollapse(collapse, collapse.open);
    }
  }
  
  function toggleCollapse(collapse, open, updateHash = false) {
    const body = collapse.querySelector('.collapse__body');

    if (open) {
      // Open
      collapse.open = true;
      body.style.maxHeight = 'none';
      const bodyCoords = body.getBoundingClientRect();
      body.style.maxHeight = '0';
      setTimeout(() => {
        body.style.maxHeight = bodyCoords.height + 'px';
      }, 25);
      
      if (updateHash) {
        window.history.replaceState(null, '', `#${collapse.id}`);
      }

      // Scroll into view
/*
      const collapseCoords = collapse.getBoundingClientRect();
      const offsetBottom = window.innerHeight - (collapseCoords.bottom + bodyCoords.height);
      const fitToPage = (collapseCoords.height + bodyCoords.height) < window.innerHeight;

      console.log('fit:', fitToPage, offsetBottom);
      console.log(collapseCoords.height, bodyCoords.height, window.innerHeight);

      if (offsetBottom < 0 && fitToPage) {
        window.scrollBy({
          top: -offsetBottom,
          behavior: 'smooth'
        });
      }
*/
    } else {
      // Close
      body.style.maxHeight = '0';
      body.addEventListener('transitionend', (e) => {
        if (e.propertyName !== 'max-height') return;
        collapse.open = false;
      }, {
        once: true
      });
    }
  }
}

export default Collapse;
