// Nav

const Nav = () => {
  const nav = document.querySelector('.nav');
  const navToggle = document.querySelectorAll('.nav-toggle');
  const navOpenClass = 'nav-open';
  
  if (!nav) return;

  function handleClick() {
    document.body.classList.toggle(navOpenClass);
  }

  navToggle.forEach(toggle => toggle.addEventListener('click', handleClick));
  document.addEventListener('keyup', e => {
    if (e.key === 'Escape' && document.body.classList.contains(navOpenClass)) {
      document.body.classList.remove(navOpenClass);
    }
  });
}

export default Nav;
